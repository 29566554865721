import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import Meta from '../shared/Meta';

// Selectors
import { getCityConfig } from '../../selectors';

import styles from '../../../stylesheets/public/layouts/ErrorPage.module.postcss';

class ErrorPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { cityConfig } = this.props;
    return (
      <div className={styles.ErrorPage}>
        <Meta title={`500 Server Error - ${cityConfig.site_title}`} />
        <div className={styles.ErrorPage_content}>
          <h1>There was an error 🙁</h1>
          <Link className="Button u-mt2" to={'/'}>
            Return Home
          </Link>
        </div>
      </div>
    );
  }
}

ErrorPage.propTypes = {
  ui: PropTypes.object,
  history: PropTypes.object,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
